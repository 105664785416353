import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, ModalVariables, Select, Toggle, TopProgress } from "../../../../../components";
import {
  Container,
  SelectWrapper,
  SelectRow,
  RefreshWrapper,
  RefreshText,
  RotateImg,
} from './styles'
import { useQueryParam, StringParam } from 'use-query-params';
import Refresh from './restart.svg'
import { useInstance } from "../../../../../context/instanceContext";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useLocalStorage } from "../../../../../hooks/useLocalStorage";
import ContentLoader, { List } from 'react-content-loader'
import { useTheme } from "styled-components";

const Network:FC = () => {
  const {setDataToLs, getDataToLs} = useLocalStorage()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [selectedNetworks, setSelectedNetorks] = useState<any[]>([])
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [toggleIp, setToggleIp] = useState<boolean>(getDataToLs({key:'instance.network'})?.assignFloating || false)
  const [selectedSecurity, setSelectedSecurity] = useState<any[]>(getDataToLs({key:'instance.network'})?.selectedSecurity || [])
  const [initialValues, setInitialValues] = useState<any>({
    ssh:getDataToLs({key:'instance.network'})?.ssh,
    firewall:getDataToLs({key:'instance.network'})?.firewall,
    network:getDataToLs({key:'instance.network'})?.network,
  })
  const theme:any = useTheme()
  const [t] = useTranslation()


  const editSecurity = (sec:any) => {
    const founded = selectedSecurity.find((item:any) => item.value === sec?.value)
    if(founded) {
      setSelectedSecurity((prev:any) => {
        return prev.filter((item:any) => item.value !== sec?.value)
      })
    } else {
      setSelectedSecurity((prev) => [...prev, sec])
    }
  } 
  const onSubmit = (values:any) => {
    setStep("finally")
    setNetworkData({...values, assignFloating:toggleIp})
  }
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      ssh: yup.string(),
      firewall: yup.string(),
      network:yup.string().required(`${t('REQUIRED_FIELD')}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const {
    sshKeys,
    networks,
    pageLoading,
    groups,
    setNetworkData,
    configureData,
    networkData,
    loadingRefresh,
    getCreateOptions,
    setConfigureData,
    
  } = useInstance()
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModal = () => {
    // if(modalType === 'delete'){
    //   _deleteInstances()
    // }
    setViewModal(false)
    setActiveAnimation(false)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }
  useEffect(() => {
    setDataToLs({key:'instance.network', data:{...values, assignFloating:toggleIp}})
    setNetworkData({...values, assignFloating:toggleIp})

  },[values, selectedSecurity, toggleIp])
  useEffect(() => {
    if(!configureData) {
      setConfigureData(getDataToLs({key:'instance.configure'}))
    }
    if(!networkData) {
      setNetworkData(getDataToLs({key:'instance.network'}))
    }
  },[])
  console.log('network', networkData);
  if(pageLoading) return (
    <>
      <TopProgress loading={pageLoading}/>
      <ContentLoader backgroundColor={theme.palette[10]} viewBox="0 0 500 800" height={800} width={500}>
        <rect x="0" y="45" rx="4" ry="4" width="524" height="144" />

        <rect x="0" y="209" rx="4" ry="4" width="524" height="144" />

        <rect x="0" y="373" rx="4" ry="4" width="524" height="144" />


        {/* <rect x="0" y="335" rx="4" ry="4" width="543" height="308" /> */}
        <rect x="100" y="533" rx="4" ry="4" width="80" height="40" />
        <rect x="0" y="533" rx="4" ry="4" width="80" height="40" />
      </ContentLoader>
    </>
  )
  return(
    <Container>
      <SelectWrapper>
        <SelectRow>
          <div style={{width:'360px'}}>
            <Select
              required
              toolTip={false}
              // multi
              // multipleOnChange={editChecked}
              data={networks}
              selectedValue={values['network']}
              test_id={'instance-network-select'}
              onChange={(e) => {
                handleChange('network')(e)
              }}
              title={t("NETWORK")}
              disabled={networks.length === 0}
              {...formikProps}
              fieldName='network'
            />
          </div>
          {/* <RefreshWrapper>
            <img src={Refresh}/>
            <RefreshText>
              {t("REFRESH_LIST")}
            </RefreshText>
          </RefreshWrapper> */}
        </SelectRow>
        {/* <Button
          variant="primary"
          size="small"
          customStyles={{
            marginRight:"10px",
            marginTop:'10px',
            padding:'10px',
          }}
          onClick={() => {}}
          title={t("CREATE_NETWORK")}
        /> */}
      </SelectWrapper>
      <SelectWrapper>
        <SelectRow>
          <div style={{width:'360px'}}>
            <Select
              toolTip={false}
              data={sshKeys}
              selectedValue={values['ssh']}
              test_id={'instance-ssh-select'}
              onChange={(e) => {
                handleChange('ssh')(e)}}
              title={t("SSH_KEY")}
              disabled={sshKeys.length === 0}
              {...formikProps}
              fieldName='ssh'
            />
          </div>
          <RefreshWrapper>
            <RotateImg active={loadingRefresh} src={Refresh}/>
            <RefreshText onClick={getCreateOptions}>
              {t("REFRESH_LIST")}
            </RefreshText>
          </RefreshWrapper>
        </SelectRow>
        <div style={{display:'flex', marginTop:'10px',}}>
          <Button
            variant="primary"
            size="small"
            customStyles={{marginRight:"10px", padding:'9px'}}
            onClick={() => openModal('generate-ssh')}
            title={t("CREATE_NEW_KEY")}
          />
          <Button
            variant="stroke"
            size="small"
            onClick={() => openModal('create-ssh')}
            title={t("ADD_PUBLIC_SSH")}
          />
        </div>
      </SelectWrapper>
      {/* <SelectWrapper>
        <SelectRow>
          <div style={{width:'360px'}}>
            <Select
              toolTip={false}
              data={groups}
              selectedValue={selectedSecurity}
              multipleOnChange={editSecurity}
              multi
              disabled={groups.length === 0}
              // selectedValue={values['firewall']}
              onChange={(e) => handleChange('firewall')(e)}
              // multi
              title={t("FIREWALL")}
            />
          </div>
        </SelectRow> */}
        {/* <Button
          variant="primary"
          size="small"
          customStyles={{marginRight:"10px", marginTop:'10px', padding:'10px'}}
          onClick={() => {}}
          title={t("CREATE_PORT")}
        /> */}
      {/* </SelectWrapper> */}
      <Toggle value={toggleIp} onChange={() => setToggleIp(!toggleIp)} title={t("ASSIGN_EXTERNAL_IP")}/>
      <div style={{display:'flex', marginTop:'40px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep("configure")}
          title={t("BACK")}
        />
      </div>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
      />
    </Container>
  )
}

export default Network
