import React, {FC, useEffect, useState} from "react";
import {
  Container,
  NavWrapper,
  TitleBig
} from './styles'
import {
  Overview,
  BillingHistory,
  Invoices,
  Services,
} from './components'
import { Tabs } from "../../components";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/user.context";

const BillingPage:FC = () => {
  const [t] = useTranslation()
  const {appMenu} = useAuth()
  const [activeTab, setActiveTab] = useState<string>()
  const [filteredRoutes, setFilteredRoutes] = useState<any[]>([])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("OVERVIEW"),
      slug:'overview',
      id:'1'
    },
    {
      title:t("SERVICES"),
      slug:'services',
      id:'2'
    },
    {
      title:t("INVOICES"),
      slug:'invoices',
      id:'3'
    },
    {
      title:t("BILLING_HISTORY"),
      slug:'billing_history',
      id:'4'
    },
  ])
  const location = useLocation()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  const SidebarsRoutes = [
    {
      slug:"billing.services",
      rootPath:'billing',
      component:(
        <>
          <Route path='/services' element={<Services />}/>
        </>
      )
    },
    {
      slug:"billing.invoices",
      rootPath:'billing',
      component:(
        <>
          <Route path='/invoices' element={<Invoices />}/>
        </>
      )
    },
    {
      slug:"billing.history",
      rootPath:'billing',
      component:(
        <>
          <Route path='/billing_history' element={<BillingHistory />}/>
        </>
      )
    },
  ]
  useEffect(() => {
    setFilteredRoutes(SidebarsRoutes.filter((route:any) => appMenu?.includes(route.slug)))
  },[appMenu])
  return(
    <Container>
      <TitleBig>{t("BILLING")}</TitleBig>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="overview" />} /> 
        <Route path='/overview' element={<Overview />}/>
        {/* <Route path='/billing_history' element={<BillingHistory />}/>
        <Route path='/invoices' element={<Invoices />}/>
        <Route path='/services' element={<Services />}/> */}
        {filteredRoutes.map((route:any) => route.component)}
      </Routes>
    </Container>
  )
}

export default BillingPage